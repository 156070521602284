import apiBaseService from './apiBaseService'

import { useAuthStore } from '../../store/auth'

export default {
  data () {
    return {
      authStore: useAuthStore(),
    }
  },
  mixins: [apiBaseService],
  computed: {
    token () {
      return (this.authStore.token) ? this.authStore.token : '';
    },
  },
  methods: {
    async getDomains () {
      return await this.apiRequest({
        method: 'get',
        url: '/domains'
      })
    },
    async getPartnerDomains () {
      return await this.apiRequest({
        method: 'get',
        headers: { authorization: this.token },
        url: '/partner/domains'
      })
    },
    async getBasicDomains () {
      return await this.apiRequest({
        method: 'get',
        url: '/partner/basic-domains',
      });
    },
  },
}
